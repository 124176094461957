import React from 'react'
import "./Footer.css"
function Footer() {
    return (
        <>
            <footer className='mainfoot'>
                <h2 className='Logo'>
                    ZINA's CARS
                </h2>
                <span className='logojj'>Luxury for you</span>
                <a href="/privacy">Privacy Policy</a>
                <p>
                    © 2023 ZINA's CARS. All Rights reserved.
                </p>


            </footer>


        </>
    )
}

export default Footer;
